let workingTimer;

const $toast = () => $("#toast");

/**
 * 지정된 유형과 메시지로 토스트 알림을 표시합니다.
 *
 * @param {string} [type="info"] - 토스트의 유형으로, 스타일을 결정합니다. 
 * 일반적인 유형으로는 "info", "success", "error" 등이 있습니다.
 * @param {string} message - 토스트 알림에 표시할 메시지입니다.
 */
function toast(type = "info", message) {
    setToastStyle();
    $toast().remove();
    $('body').prepend($(getToastHtml({type, message})));
    $toast().fadeIn(200);
    if (workingTimer) clearTimeout(workingTimer);
    workingTimer = setTimeout(() => {
        $toast().fadeOut(200, () => $toast().remove());
    }, 2500);
}

function getToastHtml({type, message}) {
    return `
        <div id="toast" class="alert-wrap">
            <div class="alert-type ${type}">
                <div class="text">${message}</div>
            </div>
        </div>
    `
}

function setToastStyle() {
    if ($("#toastStyle").length > 0) return;
    $("head").append(`
        <style id="toastStyle">
        .alert-wrap {
          display: none;
          z-index: 9999;
          top: 140px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          position: fixed;
        }
        .alert-wrap .text {
          white-space: pre;
          word-break: keep-all;
        }
        .alert-type {
          border-radius: 50px;
          padding: 9px 17px;
          text-align: center;
          font-size: 13px;
          z-index: 13;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
        body[data-enter-id="KSFC_1"] .alert-type.success,
        body[data-enter-id="KSFC_1"] .alert-type.info{
          background: #284E96;
          border: 1px solid #284E96;
          color: #fff;
        }
        .alert-type.success {
          background: #6449fc;
          border: 1px solid #6449fc;
          color: #fff;
        }
        .alert-type.info {
          background: #623ad6;
          border: 1px solid #623ad6;
          color: #fff;
        }
        .alert-type.error {
          background: #ff3434;
          border: 1px solid #ff3434;
          color: #fff;
        }
        .alert-wrap.mini .alert-type {
          font-size: 13px;
        }
        </style>`);
}

export {toast}
