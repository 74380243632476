import {Ajax, AJAX_OPTION} from './Ajax';
import {Paging} from './Paging';
import {VirtualScroll} from "./virtual-scroll/VirtualScroll.js";
import {DraftRemarkData} from '../page/main/detail/data-control/data/draftRemarkData.js'
import {DebounceNThrottle} from './DebounceNThrottle';
import {toast} from './Toast';
import {confirm} from './Confirm';
import {prompt} from './Prompt';
import {isKey, isAvailableKeyCode, isUpperCaseKeyCode} from './Keycode';
import {Validate} from './Validate';
import {ViewController} from './ViewController';
import {closeLayerByClass, closeLayerById} from './Close';
import * as LocalStorage from './LocalStorage';
import {load, openLoadingPop, closeLoadingPop, updateLoadingPop, removeLoading, loadInnerStraight} from './Loading';
import {
    copyClipboard,
    isFunc,
    isAct,
    requireIIFE,
    noop,
    isTarget,
    n2v,
    isClientOs,
    exist,
    exists,
    empty,
    numberWithCommas,
    attr,
    attrs,
    range,
    toggleAction,
    showNHide,
    showNRemove,
    isBrowser,
    getCookie,
    setCookie,
    deleteCookie,
    guid,
    isJson,
    getUUID,
    pipe,
    uniq,
    deepCompare,
    logPipe,
    calculateMs,
    isServerOn,
    getServerMode,
    delay,
    chunk,
    clog,
    elog,
    mlog,
    isMobile,
    isTablet,
    isEncodedUrl,
    isGlobal,
    isGuest,
} from './Often';
import * as Convert from './Convert';
import jqueryUi from "./JqueryUi";
import * as Json from './Json';
import * as TagUtil from './TagUtil';
import {
    convertOnSave,
    convertOnRead,
    removeMentionTag,
    convertOnCopy,
    convertHtmlCode,
    removeUrlTag,
    removeServerTag, convertDiv2Newline
} from './TagUtilV2'
import * as Window from './Window';
import * as OutClick from './OutClick';
import * as File from './File';
import * as Caret from './Caret';
import * as Upload from './Upload';
import * as Excel from './Excel';
import {connectSocket} from './Socket';
import * as SweetAlert from './SweetAlert.js';
import {
    getRequest,
    getSortable,
    getOneDrive,
    getJuiChart,
    getToastChart,
    getExcelLib,
    getSlick,
    getFullCalendar,
    getEWSFullCalendar,
    getCKEditor,
    getVideoViewer,
    getFlatpickr,
    getConfirmDate,
    getGoogleMap,
    getGoogleDrive,
    getActiveLib,
    getHtml2Pdf,
    getEmoji,
    loadScript,
    getGoogleAccountApi,
} from '../integrated/lib.integrated.js';
import {DOMOperator} from "./DOMOperator.js";
import {TargetPing} from "./Ping.js";
import { ResourceIdCrypto } from './CryptoUtil.js'

const util = (() => {
    return {
        ...{Ajax, AJAX_OPTION},
        Paging,
        VirtualScroll: VirtualScroll,
        DraftRemarkData: DraftRemarkData,
        ...{Paging},
        DebounceNThrottle,
        ...{toast, confirm, prompt},
        ...{isKey, isAvailableKeyCode, isUpperCaseKeyCode},
        ...{Validate},
        ...{ViewController},
        ...{closeLayerById, closeLayerByClass},
        ...LocalStorage,
        ...{load, openLoadingPop, closeLoadingPop, updateLoadingPop, removeLoading, loadInnerStraight},
        ...Convert,
        ...Json,
        ...TagUtil,
        ...{
            convertOnCopy,
            convertOnSave,
            convertDiv2Newline,
            convertOnRead,
            removeMentionTag,
            convertHtmlCode,
            removeUrlTag
        },
        ...Window,
        ...OutClick,
        jqueryUi,
        ...File,
        ...Caret,
        ...Upload,
        ...Excel,
        connectSocket,
        SweetAlert,
        copyClipboard,
        isFunc,
        isAct,
        requireIIFE,
        noop,
        isTarget,
        n2v,
        isClientOs,
        exist,
        exists,
        empty,
        numberWithCommas,
        attr,
        attrs,
        range,
        isEncodedUrl,
        toggleAction,
        showNHide,
        showNRemove,
        isBrowser,
        getCookie,
        setCookie,
        deleteCookie,
        guid,
        isJson,
        getUUID,
        pipe,
        uniq,
        deepCompare,
        logPipe,
        isServerOn,
        getServerMode,
        calculateMs,
        delay,
        chunk,
        clog,
        elog,
        mlog,
        isMobile,
        isTablet,
        isGlobal,
        isGuest,
        TargetPing,
        lib: {
            loadScript,
            getRequest, //32KB
            getSortable, //44KB
            getOneDrive, //60KB
            getJuiChart, //336KB
            getToastChart, //613KB
            getExcelLib, //150KB
            getSlick, // 61KB
            getFullCalendar, //460KB
            getEWSFullCalendar, //968KB
            getCKEditor, //179KB
            getVideoViewer, //577KB
            getFlatpickr, //50KB
            getConfirmDate, //4KB
            getGoogleMap, //220KB
            getGoogleDrive, //130KB
            getActiveLib,
            getHtml2Pdf,
            getEmoji,
            getGoogleAccountApi: getGoogleAccountApi,
        },
        DOMOperator: DOMOperator,
        ResourceIdCrypto,
    }
})();
export default util;
