import {isTarget, exist, noop} from "./Often";
import {isKey} from "./Keycode.js";

const $confirm = () => $("#confirm");

/**
 * 확인 팝업을 표시하는 함수입니다. 다양한 옵션을 통해 팝업의 내용을 커스터마이즈할 수 있습니다.
 *
 * @param {Object} options - 확인 팝업을 위한 설정 옵션입니다.
 * @param {Object} [options.text] - 팝업의 텍스트 옵션입니다.
 * @param {string} [options.text.contents] - 팝업의 주요 내용 텍스트입니다.
 * @param {string} [options.text.subContents] - 팝업의 부가 내용 텍스트입니다.
 * @param {string} [options.text.more] - 추가 정보 텍스트입니다.
 * @param {string} [options.text.submit] - 제출 버튼의 텍스트입니다.
 * @param {string} [options.text.cancel] - 취소 버튼의 텍스트입니다.
 * @param {Object} [options.callback] - 팝업 동작에 대한 콜백 함수입니다.
 * @param {Function} [options.callback.submitFn] - 제출 시 호출되는 함수입니다.
 * @param {Function} [options.callback.cancelFn] - 취소 시 호출되는 함수입니다.
 * @param {Function} [options.callback.finalFn] - 팝업 동작 후 호출되는 함수입니다.
 * @param {Object} [options.display] - 팝업의 표시 옵션입니다.
 * @param {boolean} [options.display.cancelBtn] - 취소 버튼을 표시할지 여부입니다.
 * @param {boolean} [options.persist] - 동작 후 팝업을 유지할지 여부입니다.
 */
function confirm(options = {}) {

    const $body = $('body');
    const {text = {}, callback = {}, display = {}, persist = false} = options;
    const {
        title = '',
        contents = i18next.t(common.askChange, {val : dictionary.status}),
        subContents = '',
        more = '',
        submit = i18next.t(dictionary.confirm) || '확인',
        cancel = i18next.t(dictionary.cancel) || '취소',
    } = text;
    const {
        cancelBtn = true,
        header = false,
        closeBtn = false
    } = display;

    const moreText = more.replace(/\r\n/g, '<br>');

    const closeConfirmPop = () => {
        $confirm().remove();
        $("#confirmStyle").remove();
        $body.css('overflow', 'auto');
    }

    const {
        submitFn = closeConfirmPop,
        cancelFn = closeConfirmPop,
        finalFn = noop,
    } = callback;

    closeConfirmPop();
    setConfirmStyle();

    $body.prepend($(getConfirmHtml({
        headerDisplay : header,
        closeBtn: closeBtn,
        titleText : title,
        contentsText: contents,
        subContentsText : subContents,
        moreText,
        cancelText: cancel,
        submitText: submit,
        cancelBtnDisplay: cancelBtn
    })));
    $confirm().on("mousedown mouseup click", (e) => {
        e.stopPropagation();
    })
    $confirm().on("click", async (e) => {
        const $confirm = $(e.currentTarget);
        switch (true) {
            case isTarget(e, ".btn-close-x") :
                await cancelFn($confirm, "close");
                if (persist) return;
                closeConfirmPop();
                break;
            case isTarget(e, ".cancel"):
                await cancelFn($confirm, "cancel");
                if (persist) return;
                closeConfirmPop();
                break;
            case !isTarget(e, ".confirm-content-area") :
                await cancelFn($confirm, "outside");
                if (persist) return;
                closeConfirmPop();
                break;
            case isTarget(e, ".contents-more") :
                const $contentsMore = $(e.target).closest(".contents-more");
                const isActive = $contentsMore.hasClass("active");
                $contentsMore.next()[isActive ? 'slideUp' : 'slideDown'](400);
                $contentsMore.toggleClass("active");
                break;
            case isTarget(e, ".submit") :
                await submitFn($confirm);
                closeConfirmPop();
                break;
            default:
                break;
        }
    })

    $confirm().off("keydown").on("keydown", async (e) => {
        e.stopPropagation();
        if(isKey(e, "ENTER")) {
            await submitFn($confirm);
            closeConfirmPop();
        }
    })

    $confirm().fadeIn(200, () => {
        $confirm().focus();
    });
    finalFn($confirm());
    $body.css('overflow', 'hidden');
}

function getConfirmHtml({headerDisplay , titleText , closeBtn , contentsText, subContentsText , moreText, cancelText,
                            submitText, cancelBtnDisplay}) {
    return `
        <div id="confirm" class="dimmed-area" tabindex="0">
            <div class="confirm-content-area">
                ${closeBtn ? `<header>
                        <button class="btn-close-x" id="createEventClose"></button>
                    </header>` : ''}
                ${headerDisplay ? `<h2>${titleText}</h2>` : ``}
                <div>
                <p>${contentsText}</p>
                      ${exist(subContentsText) ? `<p style="color:#FE6565">${subContentsText}</p>` : ''}  
                </div>
                ${exist(moreText) ? `<div>
                    <div class="contents-more">${i18next.t(common.showDetail)}</div>
                    <div class="contents-more-area">${moreText}</div>
                </div>` : ''}
                <div class="btn-area">
                    ${cancelBtnDisplay ? `<div class="cancel">${cancelText}</div>` : ''}
                    <div class="submit">${submitText}</div>
                </div>
            </div>
        </div>
    `
}

function setConfirmStyle() {
    if ($("#confirmStyle").length > 0) return;
    $("head").append(`
        <style id="confirmStyle">
        .dimmed-area {
            position: fixed;
            display: flex;
            align-items: center;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,.6);
            /*z-index: 101;*/
            z-index: 2101;
        }
        .confirm-content-area {
            background: #fff;
            border-radius: 10px;
            width: 450px;
            margin: 0 auto;
            padding: 20px;
        }
        .confirm-content-area p:first-child {
            margin-top: 12px;
        }
        .confirm-content-area p:last-child {
            margin-bottom: 25px;
        }
        .confirm-content-area p {
            /*margin: 12px 0 25px 0;*/
            text-align: center;
            white-space: break-spaces;
        }
        .confirm-content-area .contents-more {
            text-align: center;
            color: #aaa;
            margin-bottom: 20px;
            cursor: pointer;
        }
        .confirm-content-area .contents-more::after {
            position: relative;
            top: -2px;
            left: 3px;
            display: inline-block;
            content: "";
            width: 8px;
            height: 6px;
            background-image: url(/flow-renewal/assets/images/sprite-all-task.png);
            background-position: -403px -3px;
            background-size: 922px auto;
        }
        .confirm-content-area .contents-more.active::after {
            background-position: -427px -3px;
        }
        .confirm-content-area .contents-more-area {
            display: none;
            max-height: 200px;
            padding: 20px;
            color: rgb(170, 170, 170);
            background: rgb(238, 238, 238);
            margin: 20px 0;
            border-radius: 5px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .confirm-content-area .btn-area {
            width: 100%;
            text-align: center;
        }
        .confirm-content-area .btn-area div {
            display: inline-block;
            width: 47%;
            line-height: 34px;
            border: 1px solid #ddd;
            border-radius: 4px;
            color: #555;
            font-size: 13px;
            cursor: pointer;
        }
        .confirm-content-area .btn-area .submit {
            color: #fff;
            background: #6449fc;
            margin-left: 8px;
        }
        </style>`);
}

export {confirm}
